import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../services/utils';
import AccountsTableView from './accounts-table-view';
import AccountsTableSubAccountPanel from './accounts-table-sub-account-panel';

class AccountsTable extends Component {
  static groupByAccount(accounts) {
    const groupedAccounts = [];
    accounts.forEach(account => {
      const group = groupedAccounts.find(
        g => g.header.account === account.account
      );
      const newGroup = {
        header: {
          account: account.account,
          exchangeCode: account.exchangeCode || account.account,
          balanceEur: account.unconfirmedBalanceEur || account.balanceEur,
          value: account.value,
          change24hPercentage: account.change24hPercentage,
          exposureEur: account.unconfirmedExposureEur || account.exposureEur,
          exposure: account.exposure,
          cryptoExposure: account.cryptoExposure
        },
        rows: []
      };
      if (
        account.amount !== 0 ||
        account.account === 'manual' ||
        (account.account === 'Swissquote' && account.balanceEur !== 0)
      ) {
        if (group) {
          group.rows.push(account);
          group.header.balanceEur +=
            account.unconfirmedBalanceEur || account.balanceEur;
          group.header.value += account.value;
          group.header.change24hPercentage += account.change24hPercentage;
          group.header.exposureEur +=
            account.unconfirmedExposureEur || account.exposureEur;
          group.header.exposure += account.exposure;
          group.header.cryptoExposure += account.cryptoExposure;
        } else {
          if (
            account.amount !== 0 ||
            ['manual', 'Okex', 'Swissquote'].includes(account.account)
          ) {
            newGroup.rows = [account];
          }
          groupedAccounts.push(newGroup);
        }
      } else if (!group) {
        groupedAccounts.push(newGroup);
      }
    });
    return groupedAccounts;
  }

  static groupBySubAccount(subaccounts) {
    const groupedSubAccounts = [];
    subaccounts.forEach(subaccount => {
      const group = groupedSubAccounts.find(
        g => g.header.subaccount === subaccount.subaccount
      );

      if (group) {
        group.rows.push(subaccount);
        group.header.balanceEur +=
          subaccount.unconfirmedBalanceEur || subaccount.balanceEur;
        group.header.value += subaccount.value;
        group.header.change24hPercentage += subaccount.change24hPercentage;
        group.header.exposureEur +=
          subaccount.unconfirmedExposureEur || subaccount.exposureEur;
        group.header.exposure += subaccount.exposure;
        group.header.cryptoExposure += subaccount.cryptoExposure;
      } else {
        const newGroup = {
          header: {
            subaccount: subaccount.subaccount,
            balanceEur:
              subaccount.unconfirmedBalanceEur || subaccount.balanceEur,
            value: subaccount.value,
            change24hPercentage: subaccount.change24hPercentage,
            exposureEur:
              subaccount.unconfirmedExposureEur || subaccount.exposureEur,
            exposure: subaccount.exposure,
            cryptoExposure: subaccount.cryptoExposure
          },
          rows: [subaccount]
        };
        groupedSubAccounts.push(newGroup);
      }
    });
    // if there is a group with and without subaccount,
    // the one without needed to be named as 'main'
    const groupWithoutSubAccount = groupedSubAccounts.find(
      g => g.header.subaccount === null || g.header.subaccount === ''
    );
    const groupWithSubAccount = groupedSubAccounts.find(
      g => g.header.subaccount !== null && g.header.subaccount !== ''
    );
    if (groupWithSubAccount && groupWithoutSubAccount) {
      groupWithoutSubAccount.header.subaccount = 'main';
    }
    return groupedSubAccounts;
  }

  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.shape()),
    startLooping: PropTypes.func,
    clearCurrencies: PropTypes.func,
    getCurrencies: PropTypes.func,
    progresses: PropTypes.arrayOf(PropTypes.shape()),
    currencies: PropTypes.arrayOf(PropTypes.shape()),
    isUpdatingProgresses: PropTypes.bool,
    isFetching: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpen: false,
      isRemoveModalOpen: false,
      isBalanceEURChartModalOpen: false,
      dataToEdit: null,
      selectedAccountToChartModal: null,
      exchangeCode: null
    };

    this.closeEditModal = this.closeEditModal.bind(this);
    this.closeRemoveModal = this.closeRemoveModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.openRemoveModal = this.openRemoveModal.bind(this);
    this.closeBalanceEURChartModal = this.closeBalanceEURChartModal.bind(this);
    this.openBalanceEurChartModal = this.openBalanceEurChartModal.bind(this);
  }

  componentDidMount() {
    const { getCurrencies } = this.props;
    getCurrencies(true, true);
  }

  componentWillUnmount() {
    const { clearCurrencies } = this.props;
    clearCurrencies();
  }

  openBalanceEurChartModal = (e, account, exchangeCode) => {
    e.stopPropagation();
    this.setState({
      isBalanceEURChartModalOpen: true,
      selectedAccountToChartModal: account,
      exchangeCode
    });
  };

  openEditModal(data) {
    const { currencies } = this.props;
    const fullData = { ...data };

    const currency = currencies.find(
      c =>
        c.symbol &&
        data.instrument &&
        c.symbol.toUpperCase() === data.instrument.toUpperCase()
    );

    fullData.currencyId = (currency && currency.id) || -1;

    this.setState({
      isEditModalOpen: true,
      dataToEdit: fullData
    });
  }

  closeRemoveModal() {
    this.setState({
      isRemoveModalOpen: false
    });
  }

  openRemoveModal() {
    this.setState({
      isRemoveModalOpen: true
    });
  }

  closeBalanceEURChartModal() {
    this.setState({
      isBalanceEURChartModalOpen: false
    });
  }

  closeEditModal() {
    this.setState({
      isEditModalOpen: false,
      dataToEdit: null
    });
  }

  render() {
    const {
      isEditModalOpen,
      dataToEdit,
      isRemoveModalOpen,
      isBalanceEURChartModalOpen,
      selectedAccountToChartModal,
      exchangeCode
    } = this.state;

    const {
      accounts,
      startLooping,
      progresses,
      isUpdatingProgresses,
      isFetching
    } = this.props;

    // get exposures only for crypto balances (doesn't include UPL position exposure)
    const extendedRecords = Utils.extendBalancesWithUPLPositions(accounts);
    // Group by accounts
    const groupedAccounts = AccountsTable.groupByAccount(extendedRecords);

    // Sort by ABC
    const sortedGroupedAccounts = groupedAccounts.sort((a, b) => {
      if (a.header.account < b.header.account) return -1;
      if (a.header.account > b.header.account) return 1;
      return 0;
    });

    const totalAssets = {
      balanceEur: 0,
      value: 0,
      exposureEur: 0,
      exposure: 0
    };

    sortedGroupedAccounts.forEach(group => {
      totalAssets.balanceEur += group.header.balanceEur;
      totalAssets.value += group.header.value;
      totalAssets.exposure += group.header.exposure;
      totalAssets.exposureEur += group.header.exposureEur;
    });

    const foldableBlocks = [];
    sortedGroupedAccounts.forEach(dataset => {
      const groupedSubAccounts = AccountsTable.groupBySubAccount(dataset.rows);

      // The header should NEVER be wrapped in <tr> tag
      const header = (
        <>
          <td colSpan={3}>
            <strong>{dataset.header.account}</strong>
          </td>
          <td className="text-right">
            {Utils.formatToMaxDigit(dataset.header.balanceEur, 0)}
          </td>
          <td className="text-right">
            {`${dataset.header.value.toFixed(2)}%`}
          </td>
          <td className="text-right">
            {Utils.formatToMaxDigit(dataset.header.exposureEur, 0)}
          </td>
          <td className="text-right">
            {`${dataset.header.exposure.toFixed(2)}%`}
          </td>
          <td className="text-right">
            {`${dataset.header.cryptoExposure.toFixed(2)}%`}
          </td>
          <td />
          <td className="text-center">
            {!isFetching ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={e => {
                  this.openBalanceEurChartModal(
                    e,
                    dataset.header.account,
                    dataset.header.exchangeCode
                  );
                }}
              >
                <i className="fa fa-bar-chart" style={{ margin: '0px' }} />
              </button>
            ) : null}
          </td>
        </>
      );

      // The elements given in the body should ALWAYS add
      // the ```style``` prop on their own render to their wrapping DOM element
      //
      // eg:
      // <tr style={style}>
      //    ...element comes here...
      // </tr>

      const body = groupedSubAccounts
        .sort((a, b) => {
          if (b.header.subaccount.toLowerCase() === 'main') {
            return 1;
          }
          if (a.header.subaccount.toLowerCase() === 'main') {
            return -1;
          }
          if (
            a.header.subaccount.toUpperCase() >
            b.header.subaccount.toUpperCase()
          ) {
            return 1;
          }
          return -1;
        })
        .map(subaccount => {
          return (
            <AccountsTableSubAccountPanel
              key={`${subaccount.rows[0].account}${subaccount.header.subaccount}`}
              header={subaccount.header}
              dataSet={subaccount.rows}
              progresses={progresses}
              isUpdatingProgresses={isUpdatingProgresses}
              isFetching={isFetching}
              openEditModal={this.openEditModal}
            />
          );
        });

      // Add the header and the body as an object to the blocks array
      foldableBlocks.push({ key: dataset.header.account, header, body });
    });

    const headers = [
      {
        name: 'Account',
        key: 'account',
        options: { className: 'text-left' }
      },
      {
        name: 'Instrument',
        key: 'instrument',
        options: { className: 'text-right' }
      },
      {
        name: 'Amount',
        key: 'amount',
        options: { className: 'text-right' }
      },
      {
        name: `Balance ${process.env.REACT_APP_BASE_CURRENCY}`,
        key: 'balanceEur',
        options: { className: 'text-right' }
      },
      {
        name: '% of assets',
        key: 'percentageOfAssets',
        options: { className: 'text-right' }
      },
      {
        name: `Net exposure ${process.env.REACT_APP_BASE_CURRENCY}`,
        key: 'netExposureEur',
        options: { className: 'text-right' }
      },
      {
        name: 'Net Exposure %',
        key: 'netExposurePercentage',
        options: { className: 'text-right' }
      },
      {
        name: 'Net Crypto Exposure %',
        key: 'netCryptoExposurePercentage',
        options: { className: 'text-right' }
      },
      {
        name: 'Last Update UTC',
        key: 'lastUpdate',
        options: { className: 'text-right' }
      },
      {
        name: 'Chart',
        key: 'balanceEURChart',
        options: {
          className: 'text-center',
          style: { visibility: 'hidden', width: '130px' }
        }
      }
    ];

    return (
      <AccountsTableView
        headers={headers}
        foldableBlocks={foldableBlocks}
        closeEditModal={this.closeEditModal}
        isEditModalOpen={isEditModalOpen}
        dataToEdit={dataToEdit}
        isRemoveModalOpen={isRemoveModalOpen}
        openRemoveModal={this.openRemoveModal}
        closeRemoveModal={this.closeRemoveModal}
        totalAssets={totalAssets}
        startLooping={startLooping}
        isBalanceEURChartModalOpen={isBalanceEURChartModalOpen}
        openBalanceEurChartModal={this.openBalanceEurChartModal}
        closeBalanceEURChartModal={this.closeBalanceEURChartModal}
        selectedAccountToChartModal={selectedAccountToChartModal}
        selectedExchangeCode={exchangeCode}
      />
    );
  }
}

export default AccountsTable;
