import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Utils from '../../../services/utils';
import BlockchainsTableView from './blockchains-table-view';
import BlockchainsTableRow from './blockchains-table-row';

class BlockchainsTable extends Component {
  static propTypes = {
    blockchains: PropTypes.arrayOf(PropTypes.shape())
  };

  // Group accounts by instrument type
  static groupByBlockchain(blockchains) {
    const groupedInstruments = [];
    blockchains.forEach(account => {
      if (
        account.amount !== 0 ||
        (account.account === 'Swissquote' && account.balanceEur !== 0)
      ) {
        const group = groupedInstruments.find(
          g => g.header.blockchain === account.blockchain
        );

        if (group) {
          group.rows.push(account);
          group.header.balanceEur +=
            account.unconfirmedBalanceEur || account.balanceEur;
          group.header.value += account.value;
          group.header.change24hPercentage += account.change24hPercentage;
          group.header.exposureEur +=
            account.unconfirmedExposureEur || account.exposureEur;
          group.header.exposure += account.exposure;
          group.header.cryptoExposure += account.cryptoExposure;
        } else {
          groupedInstruments.push({
            header: {
              blockchain: account.blockchain,
              balanceEur: account.unconfirmedBalanceEur || account.balanceEur,
              value: account.value,
              change24hPercentage: account.change24hPercentage,
              exposureEur:
                account.unconfirmedExposureEur || account.exposureEur,
              exposure: account.exposure,
              cryptoExposure: account.cryptoExposure
            },
            rows: [account]
          });
        }
      }
    });
    return groupedInstruments;
  }

  // Aggregates each group's row's by instrument (symbol)
  static aggregateRowsByInstrument(groupedBlockchains) {
    const aggregatedGroupedBlockchains = [];
    groupedBlockchains.forEach(group => {
      const newRows = [];
      group.rows.forEach(row => {
        const newRowsRow = newRows.find(r => r.instrument === row.instrument);
        if (newRowsRow) {
          newRowsRow.amount += row.amount;
          newRowsRow.unconfirmedAmount += row.unconfirmedAmount;
          newRowsRow.balanceEur += row.balanceEur;
          newRowsRow.unconfirmedBalanceEur += row.unconfirmedBalanceEur;
          newRowsRow.value += row.value;
          newRowsRow.change24hPercentage += row.change24hPercentage;
          newRowsRow.unconfirmedExposureEur += row.unconfirmedExposureEur;
          newRowsRow.exposureEur += row.exposureEur;
          newRowsRow.exposure += row.exposure;
          newRowsRow.cryptoExposure += row.cryptoExposure;
          newRowsRow.sourceTime = moment(newRowsRow.sourceTime).isAfter(
            moment(row.sourceTime)
          )
            ? newRowsRow.sourceTime
            : row.sourceTime;
        } else {
          newRows.push({
            label:
              row.type && row.type.toLowerCase() === 'margin' ? 'margin' : null,
            id: row.id,
            instrument: row.instrument,
            amount: row.amount,
            unconfirmedAmount: row.unconfirmedAmount,
            balanceEur: row.balanceEur,
            unconfirmedBalanceEur: row.unconfirmedBalanceEur,
            value: row.value,
            change24hPercentage: row.change24hPercentage,
            unconfirmedExposureEur: row.unconfirmedExposureEur,
            exposureEur: row.exposureEur,
            exposure: row.exposure,
            cryptoExposure: row.cryptoExposure,
            lastUpdate: row.lastUpdate,
            sourceTime: row.sourceTime
          });
        }
      });
      aggregatedGroupedBlockchains.push({
        header: group.header,
        rows: newRows
      });
    });
    return aggregatedGroupedBlockchains;
  }

  render() {
    const { blockchains } = this.props;

    // Groups by blockchains
    const groupedBlockchains = BlockchainsTable.groupByBlockchain(blockchains);

    // Aggreagete each gorup's rows by instrument
    const aggregatedGroupedBlockchains =
      BlockchainsTable.aggregateRowsByInstrument(groupedBlockchains);

    // Sort by ABC
    const sortedGroupedBlockchains = aggregatedGroupedBlockchains.sort(
      (a, b) => {
        if (a.header.blockchain < b.header.blockchain) return -1;
        if (a.header.blockchain > b.header.blockchain) return 1;
        return 0;
      }
    );

    const totalAssets = {
      balanceEur: 0,
      value: 0,
      exposureEur: 0,
      exposure: 0
    };

    sortedGroupedBlockchains.forEach(group => {
      totalAssets.balanceEur += group.header.balanceEur;
      totalAssets.value += group.header.value;
      totalAssets.exposure += group.header.exposure;
      totalAssets.exposureEur += group.header.exposureEur;
    });

    // Create foldable blocks
    const foldableBlocks = [];
    sortedGroupedBlockchains.forEach(dataset => {
      // The header should NEVER be wrapped in <tr> tag
      const header = (
        <>
          <td colSpan={3}>
            <strong>{dataset.header.blockchain}</strong>
          </td>
          <td className="text-right">
            {Utils.formatToMaxDigit(dataset.header.balanceEur, 0)}
          </td>
          <td className="text-right">
            {`${dataset.header.value.toFixed(2)}%`}
          </td>
          {/* <td className="text-right">
            {dataset.header.change24hPercentage.toFixed(2)}
          </td> */}
          <td className="text-right">
            {Utils.formatToMaxDigit(dataset.header.exposureEur, 0)}
          </td>
          <td className="text-right">
            {`${dataset.header.exposure.toFixed(2)}%`}
          </td>
          <td className="text-right">
            {`${dataset.header.cryptoExposure.toFixed(2)}%`}
          </td>
          <td colSpan={2} />
        </>
      );

      // The elements given in the body should ALWAYS add
      // the ```style``` prop on their own render to their wrapping DOM element
      //
      // eg:
      // <tr style={style}>
      //    ...element comes here...
      // </tr>
      const body = dataset.rows
        .sort((a, b) => (a.instrument > b.instrument ? 1 : -1))
        .map(data => (
          <BlockchainsTableRow
            key={data.id}
            instrument={data.instrument}
            amount={data.unconfirmedAmount}
            balanceEur={data.unconfirmedBalanceEur}
            value={data.value}
            change24hPercentage={data.change24hPercentage}
            exposureEur={data.unconfirmedExposureEur}
            exposure={data.exposure}
            cryptoExposure={data.cryptoExposure}
            lastUpdate={data.sourceTime}
            label={data.label}
          />
        ));

      // Add the header and the body as an object to the blocks array
      foldableBlocks.push({ key: dataset.header.blockchain, header, body });
    });

    const headers = [
      {
        name: 'Blockchain',
        key: 'blockchain',
        options: { className: 'text-left' }
      },
      {
        name: 'Instrument',
        key: 'instrument',
        options: { className: 'text-right' }
      },
      {
        name: 'Amount',
        key: 'amount',
        options: { className: 'text-right' }
      },
      {
        name: `Balance ${process.env.REACT_APP_BASE_CURRENCY}`,
        key: 'balanceEur',
        options: { className: 'text-right' }
      },
      {
        name: '% of assets',
        key: 'percentageOfAssets',
        options: { className: 'text-right' }
      },
      {
        name: `Net exposure ${process.env.REACT_APP_BASE_CURRENCY}`,
        key: 'netExposureEur',
        options: { className: 'text-right' }
      },
      {
        name: 'Net Exposure %',
        key: 'netExposurePercentage',
        options: { className: 'text-right' }
      },
      {
        name: 'Net Crypto Exposure %',
        key: 'netCryptoExposurePercentage',
        options: { className: 'text-right' }
      },
      {
        name: 'Last Update UTC',
        key: 'lastUpdate',
        options: { className: 'text-right' }
      }
    ];

    return (
      <BlockchainsTableView
        headers={headers}
        foldableBlocks={foldableBlocks}
        totalAssets={totalAssets}
      />
    );
  }
}

export default BlockchainsTable;
