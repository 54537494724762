import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InstrumentsTableRow from './instruments-table-row';
import Utils from '../../../services/utils';
import { SECOND_HEADER_COLOR } from '../../../constants/colors';
import Foldable from '../elements/foldable';

export default class InstrumentsTableInstrumentsPanel extends Component {
  static propTypes = {
    header: PropTypes.shape(),
    dataSet: PropTypes.arrayOf(PropTypes.shape()),
    style: PropTypes.shape()
  };

  constructor(props) {
    super(props);
    this.state = {
      openedGroups: []
    };
  }

  toogleBlock = key => {
    const { openedGroups } = this.state;
    let newOpenedGroups = [];
    if (openedGroups.includes(key)) {
      newOpenedGroups = openedGroups.filter(k => k !== key);
    } else {
      newOpenedGroups = [...openedGroups, key];
    }

    this.setState({
      openedGroups: newOpenedGroups
    });
  };

  getHeader = header =>
    header && header.underlyingSymbol !== undefined ? (
      <>
        <td colSpan={6}>
          <strong>{header.underlyingSymbol?.toUpperCase() || 'unknown'}</strong>
        </td>
        <td className="text-right">
          {Utils.formatToMaxDigit(header.balanceEur, 0)}
        </td>
        <td className="text-right">{`${header.value.toFixed(2)}%`}</td>
        <td className="text-right">
          {Utils.formatToMaxDigit(header.exposureEur, 0)}
        </td>
        <td className="text-right">{`${header.exposure.toFixed(2)}%`}</td>
        <td colSpan={2} />
      </>
    ) : (
      <React.Fragment
        key={`${header.currencyType}${header.underlyingSymbol}`}
      />
    );

  render() {
    const { header, dataSet, style } = this.props;

    const headerKey = `${header.currencyType}${header.underlyingSymbol}`;

    const body = dataSet
      .sort((a, b) => {
        if (a.account?.toLowerCase() > b.account?.toLowerCase()) return 1;
        if (a.account?.toLowerCase() < b.account?.toLowerCase()) return -1;

        if (a.subaccount?.toLowerCase() > b.subaccount?.toLowerCase()) return 1;
        if (a.subaccount?.toLowerCase() < b.subaccount?.toLowerCase())
          return -1;

        if (a.label?.toLowerCase() > b.label?.toLowerCase()) return 1;
        if (a.label?.toLowerCase() < b.label?.toLowerCase()) return -1;

        return -1;
      })
      .map((data, idx) => {
        return (
          <InstrumentsTableRow
            key={data.id}
            account={data.account}
            subaccount={data.subaccount}
            instrument={data.instrument}
            amount={data.unconfirmedAmount}
            balanceEur={data.unconfirmedBalanceEur}
            value={data.value}
            change24hPercentage={data.change24hPercentage}
            exposureEur={data.unconfirmedExposureEur}
            exposure={data.exposure}
            lastUpdate={data.sourceTime}
            label={data.label}
            idx={idx}
          />
        );
      });

    const blocks = [{ key: headerKey, header: this.getHeader(header), body }];

    return (
      <Foldable
        blocks={blocks}
        isFirst={false}
        headerColor={SECOND_HEADER_COLOR}
        style={style}
      />
    );
  }
}
