import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Utils from '../../../services/utils';
import DateWithTooltip from '../../date-with-tooltip/date-with-tooltip';

class BlockchainsTableRow extends Component {
  static propTypes = {
    style: PropTypes.shape(),
    instrument: PropTypes.string,
    amount: PropTypes.number,
    balanceEur: PropTypes.number,
    value: PropTypes.number,
    // change24hPercentage: PropTypes.number,
    exposureEur: PropTypes.number,
    exposure: PropTypes.number,
    cryptoExposure: PropTypes.number,
    lastUpdate: PropTypes.string,
    label: PropTypes.string
  };

  render() {
    const {
      style,
      instrument,
      amount,
      balanceEur,
      value,
      // change24hPercentage,
      exposureEur,
      exposure,
      cryptoExposure,
      lastUpdate,
      label
    } = this.props;
    return (
      <tr style={style}>
        <td className="text-left">{label}</td>
        <td className="text-right">{instrument.toUpperCase()}</td>
        <td className="text-right">
          {Utils.formatAmountMaxDigit(amount, instrument)}
        </td>
        <td className="text-right">{Utils.formatToMaxDigit(balanceEur, 0)}</td>
        <td className="text-right">{`${value.toFixed(2)}%`}</td>
        {/* <td className="text-right">{`${change24hPercentage.toFixed(2)}%`}</td> */}
        <td className="text-right">{Utils.formatToMaxDigit(exposureEur, 0)}</td>
        <td className="text-right">{`${exposure.toFixed(2)}%`}</td>
        <td className="text-right">{`${cryptoExposure.toFixed(2)}%`}</td>
        <DateWithTooltip
          date={moment(lastUpdate).utc()}
          elementType="td"
          className="text-right"
        />
      </tr>
    );
  }
}

export default BlockchainsTableRow;
